<template>
  <div>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-card elevation="2" outlined shaped tile>
      <v-card-title>{{ `${this.$t("Question")} Import` }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="brandquestions-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <DialogPOSAnswersVetable
      ref="dialogPOSAnswersVetable"
      :importExcelTemplatePathName="importExcelTemplatePathName"
      :row="selectedRow"
    />
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";
import DialogPOSAnswersVetable from "./DialogPOSAnswersVetable.vue";

export default {
  name: "brandsquestions",
  components: { DialogPOSAnswersVetable },
  data: function() {
    return {
      tableData: [],
      totaltableData: 0,

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      filterable_col_list: [],
      filterCriteria: {
        brand: {},
        surveyName: {},
        name: {}
      },
      filterlist_fetch_url: "brands/questionsimport/getfilterlist",
      showEmpty: true,

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********** Product Codes **********/
      importExcelTemplatePathName: null,
      selectedRow: null
    };
  },
  watch: {},
  computed: {
    col_brandId() {
      return {
        key: "brandId",
        title: "ID " + this.$t("brands.brand"),
        field: "brandId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_brand() {
      return {
        key: "brand",
        title: this.$t("brands.brand") + " " + this.$t("brands.name"),
        field: "brand",
        align: "left",
        width: 170,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.brandName}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brand"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brand`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brand"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_surveyName() {
      return {
        key: "surveyName",
        title: this.$t("survey") + " " + this.$t("brands.name"),
        field: "surveyName",
        align: "left",
        width: 170,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["surveyName"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`surveyName`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["surveyName"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "surveyName")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_id() {
      return {
        key: "uuid",
        title: "ID " + this.$t("Question"),
        field: "uuid",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_assignType() {
      return {
        key: "assignType",
        title: `${this.$t("type")}`,
        field: "assignType",
        align: "left",
        width: 100,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.assignTypeLabel}</span>;
        }
      };
    },
    col_name() {
      return {
        key: "name",
        title: this.$t("Question") + " " + this.$t("brands.name"),
        field: "name",
        align: "left",
        width: 170,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_goDialog() {
      return {
        key: "goDialog",
        title: this.$t("go"),
        field: "goDialog",
        align: "left",
        width: 80,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="blue darken-1"
                text
                on-click={() => this.showDialogPOSAnswers(row)}
              >
                <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
              </v-btn>
            </div>
          );
        }
      };
    },
    columns() {
      return [
        this.col_brandId,
        this.col_brand,
        this.col_surveyName,
        this.col_assignType,
        this.col_id,
        this.col_name,
        this.col_goDialog
      ];
    }
  },
  methods: {
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#brandquestions-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value
          });
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#brandquestions-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = "brands/questions";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.importExcelTemplatePathName = resp.importExcelTemplatePathName;
        this.close();
      } catch (error) {
        this.close();
      }
    },

    async update_table(selectedRow) {
      const { data } = await ApiService.get(
        `brands/questions/${selectedRow.id}`
      );
      this.selectedRow = data;
    },
    async showDialogPOSAnswers(row) {
      this.selectedRow = row;
      // const data = await this.update_table(this.selectedRow);
      this.$refs.dialogPOSAnswersVetable.showModal(row);
    },

    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.filterable_col_list = Object.keys(this.filterCriteria);
    this.init();
  }
};
</script>

<style>
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
</style>
