<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <modal
      name="modal-import-questions"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">{{
              `${this.$t("Question")} Import POS`
            }}</span>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-btn
                  v-if="importExcelTemplatePathName"
                  class="mb-2 ml-2"
                  color="blue darken-1"
                  @click="
                    downloadExcelTemplateGlobal(importExcelTemplatePathName)
                  "
                >
                  <b-icon icon="download" aria-hidden="true"></b-icon> Plantilla
                  Import
                </v-btn>
                <v-btn
                  color="success"
                  dark
                  class="mb-2 ml-2"
                  :loading="isSelectingImport"
                  @click="onUploadExcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  {{ importButtonText }}
                </v-btn>
                <input
                  ref="uploader_file"
                  class="d-none"
                  type="file"
                  accept=".xls,.xlsx"
                  @change="onFileChangedChainChannelPerBrand"
                />
                <v-btn
                  color="warning"
                  dark
                  class="mb-2 ml-2"
                  @click="downloadexcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <ve-table
                  id="brandquestions-sub-table"
                  fixed-header
                  max-height="calc(100vh - 330px)"
                  :columns="tb_columns"
                  :table-data="tableData"
                  :border-around="true"
                  :border-x="true"
                  :border-y="true"
                  :sort-option="sortOption"
                  :cell-style-option="cellStyleOption"
                  :editOption="editOption"
                  rowKeyFieldName="id"
                />
                <div v-show="showEmpty" class="empty-data">Data Empty.</div>
                <div class="table-pagination mt-2 text-right">
                  <ve-pagination
                    :total="totaltableData"
                    :page-index="pageIndex"
                    :page-size="pageSize"
                    :page-size-option="pageOptions"
                    @on-page-number-change="pageNumberChange"
                    @on-page-size-change="pageSizeChange"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import * as XLSX from "xlsx";
import { QUESTION_TYPES } from "@/views/surveys/models";

export default {
  name: "DialogPOSAnswersVetable",
  props: ["importExcelTemplatePathName", "row"],
  components: {},
  data: function() {
    return {
      /****** IMPORT && EXPORT ******/
      isSelectingImport: false,
      selectedFile: null,
      defaultImportbuttonText: "Import",

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      current_row: null,
      tableData: [],
      totaltableData: 0,
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      editOption: {
        beforeStartCellEditing: ({ row, column, cellValue }) => {
          console.log("beforeStartCellEditing");
          console.log("row::", row);
          console.log("column::", column);
          console.log("cellValue::", cellValue);
          console.log("---");
        },
        beforeCellValueChange: ({ row, column, changeValue }) => {
          console.log("beforeCellValueChange");
          console.log("row::", row);
          console.log("column::", column);
          console.log("changeValue::", changeValue);

          console.log("---");

          if (
            ["importedObjective"].includes(column.field) &&
            !/^\d+$/.test(changeValue)
          ) {
            alert("please enter a number");
            return false;
          }
        },
        afterCellValueChange: ({ row, column, changeValue }) => {
          // console.log("afterCellValueChange");
          // console.log("row::", row);
          // console.log("column::", column);
          // console.log("changeValue::", changeValue);
          // console.log("---");
          this.saveRecord(row, column, changeValue);
        }
      },
      filterable_col_list: [],
      filterCriteria: {
        pos_name: ""
      },
      filterlist_fetch_url: "brands/questions/getfilterlist",
      showEmpty: true,

      /***************************  Loading Spinner Overlay  ***************************/
      isLoadingSpinner: false
    };
  },
  watch: {},
  computed: {
    formTitle() {
      return this.$t(this.category ? this.category : "chain");
    },
    importButtonText() {
      return this.isSelectingImport
        ? "Uploading..."
        : this.defaultImportbuttonText;
    },
    thisQuestionAssignType() {
      return this.row?.assignType;
    },
    thisQuestionType() {
      let ret = null;
      if (
        this.row?.survey_question_type &&
        this.row?.survey_question_type?.type
      ) {
        ret = this.row?.survey_question_type.type;
      }
      return ret;
    },
    isKPIthisQuestion() {
      let ret = false;
      if (this.row?.isKPI) {
        return true;
      }
      return ret;
    },
    isAvailableObjective() {
      let ret = false;
      if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.NUMBER,
          QUESTION_TYPES.DECIMAL
        ].includes(this.thisQuestionType) &&
        this.isKPIthisQuestion
      ) {
        ret = true;
      }
      return ret;
    },

    col_posId() {
      return {
        key: "posId",
        title: "ID POS",
        field: "posId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_pos_name() {
      return {
        key: "pos_name",
        title: "POS " + this.$t("brands.name"),
        field: "pos_name",
        align: "left",
        width: 170,
        sortBy: "",
        // renderBodyCell: ({ row, column, rowIndex }, h) => {
        //   return <span>{row?.brandName}</span>;
        // },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["pos_name"]}
                  on-input={value => (this.filterCriteria["pos_name"] = value)}
                  placeholder="Search name"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_optionName() {
      return {
        key: "optionName",
        title: "Pro / Mat / Otros",
        field: "optionName",
        align: "left",
        width: 170
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <filterableAjaxCheckboxes
        //           v-model={this.filterCriteria["name"]}
        //           apiUrl={`${this.filterlist_fetch_url}`}
        //           columnName={`name`}
        //           isFullText={true}
        //           filterCriteria={this.filterCriteria["name"]}
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchCancel(closeFn, "name")}
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   }
        // }
      };
    },
    col_answerValueLabel() {
      return {
        key: "answerValueLabel",
        title: this.$t("Answers"),
        field: "answerValueLabel",
        align: "left",
        width: 170,
        edit: true
      };
    },
    col_importedObjective() {
      return {
        key: "importedObjective",
        title: "Objectivo",
        field: "importedObjective",
        align: "left",
        width: 170,
        edit: true
      };
    },
    col_actions() {
      return {
        key: "actions",
        title: "Actions",
        field: "actions",
        align: "center",
        width: 80,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="blue darken-1"
                on-click={() => this.saveRecord(row)}
              >
                <v-icon>mdi-content-save</v-icon>
                Save
              </v-btn>
            </div>
          );
        }
      };
    },
    tb_columns() {
      let defaultColumns = [
        this.col_posId,
        this.col_pos_name,
        this.col_optionName,
        this.col_answerValueLabel
      ];

      let actionCol = [];

      let ret = [...defaultColumns, ...actionCol];
      if (this.isAvailableObjective) {
        let objectiveCol = [this.col_importedObjective];
        ret = [...defaultColumns, ...objectiveCol, ...actionCol];
      }

      return ret;
    }
  },
  methods: {
    /****** MODAL CONFIGURATION ******/
    showModal(row) {
      this.init(row);
      this.$modal.show("modal-import-questions");
    },
    hideModal() {
      this.$modal.hide("modal-import-questions");
    },

    /****** IMPORT && EXPORT ******/
    onUploadExcel() {
      this.isSelectingImport = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingImport = false;
        },
        { once: false }
      );
      this.$refs.uploader_file.click();
    },
    async onFileChangedChainChannelPerBrand(e) {
      this.selectedFile = e.target.files[0];
      if (
        window.confirm(
          `¿Está seguro de que desea cargar este archivo de Excel?`
        )
      ) {
        this.isSelectingImport = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader_file.value = "";
        try {
          const response = await ApiService.post(
            `brands/questions/uploadexcelIdposperquestion/${this.row.id}`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            await this.getDataFromApi();
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
            await this.update_table(this.row);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingImport = false;
          this.isLoadingSpinner = false;
        } catch (error) {
          console.log(error);
          this.isSelectingImport = false;
          this.isLoadingSpinner = false;
        }
      }
    },
    async downloadexcel() {
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#brandquestions-sub-table .filterable-column"
      });
      let fetch_url = `brands/questions/downloadexcel/${this.current_row.id}?isAvailableObjective=${this.isAvailableObjective}`;
      if (query_params_string !== "") {
        fetch_url += "&" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "pregunta_imports.xlsx");
      } catch (error) {}
      this.close();
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#brandquestions-sub-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (cancelFilterKey === "pos_name") {
        this.filterCriteria[cancelFilterKey] = "";
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    async getDataFromApi() {
      this.show();
      try {
        const { pageIndex, pageSize, sorting, filterCriteria } = this;
        let query_params_string = this.getQueryParamsGlobal({
          pageIndex,
          pageSize,
          sorting,
          filterCriteria,
          filterableColumnsSelector:
            "#brandquestions-sub-table .filterable-column"
        });
        let fetch_url = `brands/questions/getItemImportedAnswers/${this.current_row.id}`;
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async saveRecord(row, column, changeValue) {
      if (row && column) {
        const changedKey = column.field;
        const { id, answerValueLabel, answerValue, importedObjective } = row;
        const surveyQuestionItem = row?.survey_question;
        let postData = {
          id,
          answerValueLabel,
          importedObjective,
          surveyQuestionItem
        };
        try {
          await ApiService.post(`brands/questions/update_pos_answer`, postData);
          // logInfo("Éxito !");
        } catch (error) {
          logError("Error occured !");
        }
      }
    },

    /****** INITIALIZE ******/
    async init(row) {
      this.current_row = row;
      this.tableData = [];
      this.totaltableData = 0;
      this.pageIndex = 1;
      this.getDataFromApi();
    }
  },
  async updated() {},
  async mounted() {
    this.filterable_col_list = Object.keys(this.filterCriteria);
  }
};
</script>

<style>
.vetable-edit-input {
  width: 100%;
  border: 1px solid #333;
}
</style>
